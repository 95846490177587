import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Col from '@emcasa/ui-dom/components/Col';
import Text from '@emcasa/ui-dom/components/Text';
import Field from '@emcasa/ui-dom/components/Field';
import Dropdown from '@emcasa/ui-dom/components/Dropdown';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "field"
    }}>{`Field`}</h1>
    <p>{`Form field wrapper extracted from Input component.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Col mb={2} mdxType="Col">
  <Field label="Label" mdxType="Field">
    <Dropdown mdxType="Dropdown">
      <Dropdown.Option value="a">Option A</Dropdown.Option>
      <Dropdown.Option value="b">Option B</Dropdown.Option>
      <Dropdown.Option value="c">Option C</Dropdown.Option>
    </Dropdown>
  </Field>
    </Col>
    <Col mb={2} mdxType="Col">
  <Field error="Invalid input!" mdxType="Field">
    <Dropdown mdxType="Dropdown">
      <Dropdown.Option value="a">Option A</Dropdown.Option>
      <Dropdown.Option value="b">Option B</Dropdown.Option>
      <Dropdown.Option value="c">Option C</Dropdown.Option>
    </Dropdown>
  </Field>
    </Col>
    <Col mb={2} mdxType="Col">
  <Field hideLabelView hideErrorView mdxType="Field">
    <Dropdown mdxType="Dropdown">
      <Dropdown.Option value="a">Option A</Dropdown.Option>
      <Dropdown.Option value="b">Option B</Dropdown.Option>
      <Dropdown.Option value="c">Option C</Dropdown.Option>
    </Dropdown>
  </Field>
    </Col>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      